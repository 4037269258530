/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const edit = [
  '24 24',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m21.9632,2.03677c-0.333,-0.33165 -0.7285,-0.59402 -1.1636,-0.77196c-0.435,-0.17793 -0.901,-0.26791 -1.371,-0.26473c-0.47,0.00318 -0.9348,0.09946 -1.3674,0.28327c-0.4326,0.18381 -0.8245,0.45151 -1.153,0.78764l-14.17756,14.17751l-1.73064,6.7515l6.75145,-1.7316l14.17755,-14.17757c0.3362,-0.3284 0.604,-0.72019 0.7879,-1.15271c0.1839,-0.43253 0.2802,-0.89721 0.2833,-1.36718c0.0032,-0.46998 -0.0868,-0.93592 -0.2648,-1.3709c-0.178,-0.43497 -0.4404,-0.83035 -0.7722,-1.16327l0,0z"/>
  <path id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m16.5034,2.47656l5.0199,5.01984"/>
  <path id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m2.73169,16.248l5.02472,5.016"/>
`,
];
