/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const care = [
  '36 36',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m19.5666,18.6135c-0.2047,0.2075 -0.4486,0.3723 -0.7175,0.4848c-0.2689,0.1124 -0.5574,0.1703 -0.8489,0.1703c-0.2915,0 -0.5801,-0.0579 -0.849,-0.1703c-0.2689,-0.1125 -0.5128,-0.2773 -0.7174,-0.4848l-7.80856,-7.9198c-0.81792,-0.78661 -1.36062,-1.81585 -1.54766,-2.93513c-0.18704,-1.11929 -0.00846,-2.26905 0.50926,-3.27888l0,0c0.41069,-0.76874 0.99412,-1.43175 1.70441,-1.93687c0.71025,-0.50512 1.52805,-0.8386 2.38905,-0.9742c0.861,-0.13561 1.7417,-0.06964 2.5729,0.19271c0.8311,0.26234 1.5901,0.71392 2.2172,1.31921l1.5298,1.48715l1.5312,-1.48715c0.6271,-0.60529 1.3861,-1.05687 2.2172,-1.31921c0.8312,-0.26235 1.7119,-0.32832 2.5729,-0.19271c0.861,0.1356 1.6788,0.46908 2.3891,0.9742c0.7103,0.50512 1.2937,1.16813 1.7044,1.93687l0,0c0.5189,1.01065 0.698,2.16172 0.5107,3.28226c-0.1873,1.12053 -0.7311,2.15076 -1.5506,2.93765l-7.8085,7.9139z"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m5.9,34.5l-2.95387,-3.492c-0.93612,-1.1702 -1.44613,-2.6241 -1.44613,-4.1226l0,-7.5854c0,-0.5834 0.23179,-1.143 0.64437,-1.5556c0.41258,-0.4125 0.97215,-0.6443 1.55563,-0.6443l0,0c0.58348,0 1.14305,0.2318 1.55563,0.6443c0.41258,0.4126 0.64437,0.9722 0.64437,1.5556l0,5.4998"/>
  <path fill="none" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m12.5002,34.4999l0,-4.3998c0.0005,-1.3051 -0.3858,-2.581 -1.1103,-3.6666l-1.67494,-2.5079c-0.14352,-0.2377 -0.33376,-0.4438 -0.55922,-0.6059c-0.22546,-0.1621 -0.48146,-0.2767 -0.75251,-0.3371c-0.27105,-0.0603 -0.55152,-0.065 -0.82444,-0.0138c-0.27292,0.0511 -0.53264,0.1571 -0.76343,0.3115l0,0c-0.41422,0.2777 -0.71321,0.6967 -0.84113,1.1787c-0.12793,0.482 -0.07606,0.9941 0.14593,1.4407l1.98294,3.2999"/>
  <path fill="none" id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m30.1001,34.5l2.9539,-3.492c0.9361,-1.1702 1.4461,-2.6241 1.4461,-4.1226l0,-7.5854c0,-0.5834 -0.2318,-1.143 -0.6444,-1.5556c-0.4125,-0.4125 -0.9721,-0.6443 -1.5556,-0.6443c-0.5835,0 -1.1431,0.2318 -1.5556,0.6443c-0.4126,0.4126 -0.6444,0.9722 -0.6444,1.5556l0,5.4998"/>
  <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m23.5,34.4998l0,-4.3999c-0.0006,-1.305 0.3858,-2.581 1.1103,-3.6665l1.6749,-2.5108c0.1429,-0.2383 0.3327,-0.4452 0.5579,-0.608c0.2252,-0.1628 0.4811,-0.2782 0.7522,-0.3392c0.271,-0.061 0.5517,-0.0664 0.8249,-0.0157c0.2733,0.0506 0.5334,0.1561 0.7646,0.3102l0,0c0.4165,0.2775 0.7173,0.6976 0.8459,1.1813c0.1286,0.4837 0.076,0.9977 -0.1478,1.4454l-1.9829,3.2999"/>
`,
];
