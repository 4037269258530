/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const cancel = [
  '24 24',
  `
  <path id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m1,4.14258l22,0"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m14.3572,1l-4.71424,0c-0.41677,0 -0.81647,0.16556 -1.11117,0.46026c-0.2947,0.2947 -0.46026,0.6944 -0.46026,1.11117l0,1.57143l7.85717,0l0,-1.57143c0,-0.41677 -0.1656,-0.81647 -0.4603,-1.11117c-0.2947,-0.2947 -0.6944,-0.46026 -1.1112,-0.46026z"/>
  <path id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m9.64282,17.4997l0,-7.85712"/>
  <path id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m14.3572,17.4997l0,-7.85712"/>
  <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m19.1919,21.5582c-0.0326,0.393 -0.2117,0.7593 -0.5018,1.0263c-0.2901,0.2671 -0.67,0.4153 -1.0644,0.4152l-11.25033,0c-0.39431,0.0001 -0.77422,-0.1481 -1.06434,-0.4152c-0.29012,-0.267 -0.46926,-0.6333 -0.50185,-1.0263l-1.452,-17.41562l17.28572,0l-1.451,17.41562z"/>

`,
];
