/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const mountain = [
  '30 30',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m5.81665,29.25l10.48335,-16.65c0.1452,-0.1891 0.332,-0.3422 0.5458,-0.4476c0.2139,-0.1054 0.4491,-0.1602 0.6875,-0.1602c0.2384,0 0.4736,0.0548 0.6875,0.1602c0.2139,0.1054 0.4006,0.2585 0.5459,0.4476l10.4833,16.65l-23.43335,0z"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m5.81667,29.2498l-4.31667,0l4.625,-8.325l2.6825,3.5767"/>
  <path fill="none" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m11.3186,20.5117l2.8823,2.4556c0.2361,0.1998 0.5387,0.3037 0.8478,0.2909c0.3091,-0.0128 0.6021,-0.1413 0.8209,-0.36l0.7918,-0.7918c0.2313,-0.2312 0.5449,-0.3611 0.872,-0.3611c0.327,0 0.6407,0.1299 0.8719,0.3611l0.7918,0.7918c0.2188,0.2187 0.5118,0.3472 0.8209,0.36c0.3091,0.0128 0.6117,-0.0911 0.8478,-0.2909l2.9021,-2.4247"/>
  <path id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m17.2251,12.0302l0,-10.5302"/>
  <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m17.2251,1.5l4.625,0l0,1.85l5.55,0l-1.85,2.775l1.85,2.775l-5.55,0l0,-0.925l-4.625,0"/>
`,
];
