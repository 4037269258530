/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const chapterDone = [
  '24 24',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m12,23c6.0751,0 11,-4.9249 11,-11c0,-6.07513 -4.9249,-11 -11,-11c-6.07513,0 -11,4.92487 -11,11c0,6.0751 4.92487,11 11,11z"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m6.1333,13.1962l2.39556,3.3998c0.09086,0.1357 0.21283,0.2478 0.35576,0.3269c0.14292,0.079 0.30266,0.1228 0.46593,0.1277c0.16327,0.0049 0.32534,-0.0293 0.47273,-0.0997c0.14739,-0.0704 0.27582,-0.175 0.37462,-0.305l7.6687,-9.70254"/>
`,
];
