/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const scale = [
  '30 30',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m25.55,1.5l-20.35,0c-2.04345,0 -3.7,1.65655 -3.7,3.7l0,20.35c0,2.0435 1.65655,3.7 3.7,3.7l20.35,0c2.0435,0 3.7,-1.6565 3.7,-3.7l0,-20.35c0,-2.04345 -1.6565,-3.7 -3.7,-3.7z"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m9.56972,10.2385c0.07655,0.1539 0.19459,0.2834 0.34082,0.3738c0.14626,0.0905 0.31486,0.1383 0.48676,0.138l9.9555,0c0.1719,0.0003 0.3405,-0.0475 0.4867,-0.138c0.1462,-0.0904 0.2643,-0.2199 0.3408,-0.3738l1.184,-2.36558c0.0552,-0.11131 0.0874,-0.23256 0.0947,-0.35656c0.0073,-0.124 -0.0104,-0.24821 -0.052,-0.36523c-0.0417,-0.11702 -0.1065,-0.22447 -0.1905,-0.31595c-0.084,-0.09149 -0.1856,-0.16513 -0.2986,-0.21656c-2.0542,-0.93223 -4.284,-1.41452 -6.5398,-1.41452c-2.2558,0 -4.4856,0.48229 -6.53974,1.41452c-0.11339,0.05141 -0.21525,0.12515 -0.29949,0.21682c-0.08424,0.09166 -0.14914,0.19937 -0.19081,0.31669c-0.04168,0.11731 -0.05928,0.24182 -0.05175,0.36609c0.00753,0.12427 0.04004,0.24574 0.09558,0.35717l1.17783,2.36311z"/>
  <path fill="none" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m13.525,16.7627c0,-1.2777 -1.4492,-2.3125 -3.2375,-2.3125c-1.78835,0 -3.23751,1.0348 -3.23751,2.3125l0,6.475c0,0.6133 0.24364,1.2015 0.67731,1.6352c0.43368,0.4337 1.02188,0.6773 1.63519,0.6773c0.61331,0 1.20151,-0.2436 1.63521,-0.6773c0.4337,-0.4337 0.6773,-1.0219 0.6773,-1.6352l0,-0.4144c0.0001,-1.8389 0.5546,-3.6351 1.591,-5.1541l0,0c0.1674,-0.2729 0.257,-0.5864 0.259,-0.9065z"/>
  <path fill="none" id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m17.225,16.7627c0,-1.2777 1.4492,-2.3125 3.2375,-2.3125c1.7884,0 3.2375,1.0348 3.2375,2.3125l0,6.475c0,0.6133 -0.2436,1.2015 -0.6773,1.6352c-0.4337,0.4337 -1.0218,0.6773 -1.6352,0.6773c-0.6133,0 -1.2015,-0.2436 -1.6351,-0.6773c-0.4337,-0.4337 -0.6774,-1.0219 -0.6774,-1.6352l0,-0.4144c-0.0001,-1.8389 -0.5546,-3.6351 -1.591,-5.1541l0,0c-0.1674,-0.2729 -0.2569,-0.5864 -0.259,-0.9065z"/>
  <path id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m12.6,5.45166l0,1.5984"/>
  <path id="svg_6" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m18.15,5.45166l0,1.5984"/>
`,
];
