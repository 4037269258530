/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const favoriteTrue = [
  '18 16',
  `
<path d="M17.5212 2.54844C17.1948 1.89348 16.7224 1.32671 16.1423 0.894166C15.5623 0.46162 14.8909 0.17549 14.1828 0.0590136C13.4747 -0.0574633 12.7498 -0.00100311 12.0669 0.223809C11.3841 0.448621 10.7626 0.835446 10.2529 1.35287L9 2.52546L7.74714 1.35287C7.23725 0.835538 6.61558 0.448854 5.93262 0.224226C5.24966 -0.000402331 4.52468 -0.0566407 3.81655 0.0600788C3.10842 0.176798 2.43712 0.463184 1.85717 0.895975C1.27722 1.32877 0.804972 1.89576 0.47879 2.5509C0.0530045 3.41613 -0.0940624 4.39764 0.0587681 5.35408C0.211599 6.31052 0.65644 7.19253 1.32923 7.8731L9 16L16.6708 7.87064C17.3436 7.19007 17.7884 6.30806 17.9412 5.35162C18.0941 4.39518 17.947 3.41367 17.5212 2.54844Z" fill="white"/>
`,
];
