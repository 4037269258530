/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const chapterInProgress = [
  '24 24',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m12,23.0002c6.0751,0 11,-4.9248 11,-11c0,-6.07509 -4.9249,-10.99996 -11,-10.99996c-6.07513,0 -11,4.92487 -11,10.99996c0,6.0752 4.92487,11 11,11z"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m8.3923,17.4025c-1.05728,-0.7055 -1.88444,-1.7056 -2.37908,-2.8765c-0.49464,-1.1709 -0.63504,-2.4611 -0.40381,-3.711c0.23122,-1.24981 0.82391,-2.40442 1.7047,-3.32084c0.88079,-0.91641 2.011,-1.5544 3.25069,-1.83498"/>
  <path id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m18.0646,14.3044c-0.3283,0.8688 -0.8398,1.6567 -1.4997,2.3102"/>
  <path id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m13.9811,18.1843c-0.8845,0.2841 -1.82,0.3734 -2.7423,0.2618"/>
  <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m12,6.94337c0.7974,0 1.4439,-0.64644 1.4439,-1.44386c0,-0.79741 -0.6465,-1.44385 -1.4439,-1.44385c-0.7974,0 -1.4438,0.64644 -1.4438,1.44385c0,0.79742 0.6464,1.44386 1.4438,1.44386z"/>
`,
];
