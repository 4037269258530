/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const smile = [
  '24 24',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m12,23c6.0751,0 11,-4.9249 11,-11c0,-6.07513 -4.9249,-11 -11,-11c-6.07513,0 -11,4.92487 -11,11c0,6.0751 4.92487,11 11,11z"/>
  <path id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m8.33371,8.7002c0.07252,0 0.14341,0.0215 0.20371,0.06179c0.06029,0.04029 0.10729,0.09756 0.13504,0.16456c0.02776,0.06699 0.03502,0.14072 0.02087,0.21185c-0.01415,0.07112 -0.04907,0.13646 -0.10035,0.18774c-0.05128,0.05127 -0.11661,0.0862 -0.18774,0.10034c-0.07113,0.01415 -0.14485,0.00689 -0.21185,-0.02086c-0.067,-0.02775 -0.12426,-0.07475 -0.16455,-0.13505c-0.04029,-0.0603 -0.0618,-0.13119 -0.0618,-0.20371c0,-0.09724 0.03863,-0.19051 0.1074,-0.25927c0.06876,-0.06876 0.16202,-0.10739 0.25927,-0.10739"/>
  <path id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m15.667,8.7002c0.0725,0 0.1434,0.0215 0.2037,0.06179c0.0603,0.04029 0.1073,0.09756 0.135,0.16456c0.0278,0.06699 0.035,0.14072 0.0209,0.21185c-0.0142,0.07112 -0.0491,0.13646 -0.1004,0.18774c-0.0512,0.05127 -0.1166,0.0862 -0.1877,0.10034c-0.0711,0.01415 -0.1449,0.00689 -0.2119,-0.02086c-0.067,-0.02775 -0.1242,-0.07475 -0.1645,-0.13505c-0.0403,-0.0603 -0.0618,-0.13119 -0.0618,-0.20371c0,-0.09724 0.0386,-0.19051 0.1074,-0.25927c0.0688,-0.06876 0.162,-0.10739 0.2593,-0.10739"/>
  <path fill="none" id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m17.9139,14.9336c-0.5462,1.1022 -1.3894,2.0299 -2.4346,2.6785c-1.0452,0.6486 -2.2509,0.9922 -3.481,0.9922c-1.2301,0 -2.43572,-0.3436 -3.48093,-0.9922c-1.04522,-0.6486 -1.88845,-1.5763 -2.43461,-2.6785"/>
`,
];
