/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const bed = [
  '32 32',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m4,15.00339l0,-3c0,-0.53043 0.21071,-1.03914 0.58579,-1.41421c0.37507,-0.37508 0.88378,-0.58579 1.41421,-0.58579l8,0c0.5304,0 1.0391,0.21071 1.4142,0.58579c0.3751,0.37507 0.5858,0.88378 0.5858,1.41421l0,3"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m16,15.00339l0,-3c0,-0.53043 0.2107,-1.03914 0.5858,-1.41421c0.3751,-0.37508 0.8838,-0.58579 1.4142,-0.58579l8,0c0.5304,0 1.0391,0.21071 1.4142,0.58579c0.3751,0.37507 0.5858,0.88378 0.5858,1.41421l0,3"/>
  <path fill="none" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m3.66667,15.00339l24.66663,0c0.7073,0 1.3856,0.281 1.8857,0.781c0.5,0.5001 0.781,1.1784 0.781,1.8857l0,6.3333l-30,0l0,-6.3333c0,-0.7073 0.28095,-1.3856 0.78105,-1.8857c0.5001,-0.5 1.17837,-0.781 1.88562,-0.781l0,0z"/>
  <path fill="none" id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m1,24.00339l0,4"/>
  <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m31,24.00339l0,4"/>
  <path fill="none" id="svg_6" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m28,15.00339l0,-9c0,-0.53043 -0.2107,-1.03914 -0.5858,-1.41421c-0.3751,-0.37508 -0.8838,-0.58579 -1.4142,-0.58579l-20,0c-0.53043,0 -1.03914,0.21071 -1.41421,0.58579c-0.37508,0.37507 -0.58579,0.88378 -0.58579,1.41421l0,9"/>
`,
];
