/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const editDocument = [
  '24 24',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m12.574,14.1562l-3.18582,0.4557l0.45487,-3.1866l8.19275,-8.19272c0.3621,-0.36214 0.8533,-0.56559 1.3654,-0.56559c0.5122,0 1.0033,0.20345 1.3655,0.56559c0.3621,0.36214 0.5656,0.85331 0.5656,1.36546c0,0.51214 -0.2035,1.00331 -0.5656,1.36545l-8.1927,8.19271z"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#656278" d="m18.115,14.2534l0,6.4368c0,0.3414 -0.1356,0.6689 -0.3771,0.9103c-0.2414,0.2414 -0.5688,0.377 -0.9103,0.377l-12.8735,0c-0.34142,0 -0.66887,-0.1356 -0.91029,-0.377c-0.24143,-0.2414 -0.37706,-0.5689 -0.37706,-0.9103l0,-12.87355c0,-0.34143 0.13563,-0.66887 0.37706,-0.9103c0.24142,-0.24142 0.56887,-0.37705 0.91029,-0.37705l6.4368,0"/>
`,
];
