/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const info = [
  '12 12',
  `
    <circle cx="6" cy="6" r="6" fill="#F0B433"/>
    <path d="M6.00004 2.6665C5.86819 2.6665 5.73929 2.7056 5.62966 2.77886C5.52003 2.85211 5.43458 2.95623 5.38412 3.07805C5.33366 3.19986 5.32046 3.33391 5.34618 3.46323C5.37191 3.59255 5.4354 3.71134 5.52864 3.80458C5.62187 3.89781 5.74066 3.9613 5.86998 3.98703C5.9993 4.01275 6.13334 3.99955 6.25516 3.94909C6.37698 3.89863 6.4811 3.81319 6.55435 3.70355C6.62761 3.59392 6.66671 3.46503 6.66671 3.33317C6.66671 3.15636 6.59647 2.98679 6.47144 2.86177C6.34642 2.73674 6.17685 2.6665 6.00004 2.6665Z" fill="white"/>
    <path d="M6.66671 8.00016H6.40004C6.25859 8.00016 6.12294 7.92992 6.02292 7.8049C5.9229 7.67988 5.86671 7.51031 5.86671 7.3335V5.66683C5.86671 5.57842 5.83861 5.49364 5.7886 5.43113C5.73859 5.36862 5.67076 5.3335 5.60004 5.3335H5.33337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
`,
];
