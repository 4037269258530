/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const chapterToDo = [
  '24 24',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m12,23c6.0751,0 11,-4.9249 11,-11c0,-6.07513 -4.9249,-11 -11,-11c-6.07513,0 -11,4.92487 -11,11c0,6.0751 4.92487,11 11,11z"/>
`,
];
