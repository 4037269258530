/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const menuDots = [
  '24 24',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m21.3774,12.0107c0,-1.2081 -0.9793,-2.18746 -2.1875,-2.18746c-1.2081,0 -2.1875,0.97936 -2.1875,2.18746c0,1.2082 0.9794,2.1875 2.1875,2.1875c1.2082,0 2.1875,-0.9793 2.1875,-2.1875z"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m7.00244,12.0107c0,-1.2081 -0.97938,-2.18746 -2.1875,-2.18746c-1.20812,0 -2.1875,0.97936 -2.1875,2.18746c0,1.2082 0.97938,2.1875 2.1875,2.1875c1.20812,0 2.1875,-0.9793 2.1875,-2.1875z"/>
  <path fill="none" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#47776E" d="m14.375,12.1875c0,-1.2081 -0.9794,-2.1875 -2.1875,-2.1875c-1.2081,0 -2.1875,0.9794 -2.1875,2.1875c0,1.2081 0.9794,2.1875 2.1875,2.1875c1.2081,0 2.1875,-0.9794 2.1875,-2.1875z"/>
`,
];
