/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const medication = [
  '36 36',
  `
  <path fill="none" id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m10.6724,25.3001c0,0.4509 0.1852,0.8833 0.5151,1.2021c0.3298,0.3188 0.7771,0.4979 1.2435,0.4979l10.5517,0c0.4664,0 0.9138,-0.1791 1.2436,-0.4979c0.3298,-0.3188 0.515,-0.7512 0.515,-1.2021l0,-18.7l-14.0689,0l0,6.8"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m25.6207,6.6l-15.82757,0c-0.23321,0 -0.45686,-0.08955 -0.62177,-0.24896c-0.1649,-0.1594 -0.25754,-0.3756 -0.25754,-0.60104l0,-2.55c0,-0.45087 0.18528,-0.88327 0.51509,-1.20208c0.3298,-0.31881 0.77709,-0.49792 1.24349,-0.49792l14.069,0c0.4664,0 0.9138,0.17911 1.2436,0.49792c0.3298,0.31881 0.5151,0.75121 0.5151,1.20208l0,2.55c0,0.22544 -0.0927,0.44164 -0.2576,0.60104c-0.1649,0.15941 -0.3885,0.24896 -0.6218,0.24896z"/>
  <path fill="none" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m5.57827,26.2409c-0.50295,0.4861 -1.1851,0.7593 -1.89638,0.7593c-0.71128,0 -1.39343,-0.2732 -1.89638,-0.7593c-0.50295,-0.4862 -0.78551,-1.1456 -0.78551,-1.8332c0,-0.6876 0.28256,-1.347 0.78551,-1.8332l6.07311,-5.865c0.24903,-0.2407 0.54468,-0.4317 0.87007,-0.562c0.32538,-0.1302 0.67412,-0.1973 1.02631,-0.1973c0.3522,0 0.7009,0.0671 1.0263,0.1973c0.3254,0.1303 0.621,0.3213 0.8701,0.562c0.249,0.2408 0.4466,0.5265 0.5813,0.8411c0.1348,0.3145 0.2042,0.6516 0.2042,0.9921c0,0.3404 -0.0694,0.6776 -0.2042,0.9921c-0.1347,0.3145 -0.3323,0.6003 -0.5813,0.8411l-6.07313,5.865z"/>
  <path id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m4.81958,19.6426l3.79159,3.6652"/>
  <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m24.7414,13.3999l-8.7932,0l0,8.5l8.7932,0l0,-8.5z"/>
`,
];
