/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const medicationCompleted = [
  '66.86 67.16',
  `
<defs><style>.cls-1{fill:#47776e;}.cls-2{fill:#fff;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_5" data-name="Layer 5"><path class="cls-1" d="M9.55,15.33l1.53,5.92a5.18,5.18,0,0,1,.11,3.36,5.27,5.27,0,0,1-2.25,2.13l-6,3.75c-1.46.9-3.11,2.19-2.87,3.89.19,1.3,1.45,2.14,2.59,2.81l6.08,3.57a5.29,5.29,0,0,1,2.49,2.31,5.36,5.36,0,0,1-.09,3.12L9.43,53.27c-.35,1.52-.61,3.37.54,4.42s2.78.68,4.2.31l6.6-1.74a5.36,5.36,0,0,1,3.42-.12,5.31,5.31,0,0,1,2.17,2.3l3.88,6.26c4.35,7,8.05-3,10.2-6.38A4.55,4.55,0,0,1,43.19,56a5.27,5.27,0,0,1,2.12.17l7.88,1.77c1.34.3,2.92.55,3.92-.39s.83-2.83.46-4.3l-1.77-7a4.86,4.86,0,0,1-.13-2.94,4.9,4.9,0,0,1,2.23-2.13L64,37.5c1.31-.81,2.78-1.85,2.86-3.38.09-1.76-1.65-3-3.18-3.86l-5.32-3c-1.15-.66-2.4-1.44-2.72-2.72a4.87,4.87,0,0,1,.14-2.27l1.8-8.14c.27-1.19.5-2.57-.24-3.54-1-1.32-3-1-4.62-.63L46,11.64a5.73,5.73,0,0,1-2.62.25c-1.83-.39-2.78-2.34-3.61-4a36.72,36.72,0,0,0-4.15-6.52A3.45,3.45,0,0,0,34.16.1c-1.56-.53-2.95,1.12-3.79,2.54L26.59,9a5.31,5.31,0,0,1-2.33,2.48,5.28,5.28,0,0,1-3.07-.1L14.28,9.78c-1.66-.39-3.74-.65-4.79.71-.82,1.07-.52,2.6-.18,3.91Z"/><path class="cls-2" d="M42.47,24.12a2.57,2.57,0,0,0-2.57-2.56H30a2.56,2.56,0,0,0-1,4.92.77.77,0,0,0-.06.3V32h.78V27.57a.89.89,0,0,1,.89-.89h9a.89.89,0,0,1,.89.89V31H33.13v7.62h7.32v2.85a.89.89,0,0,1-.89.89h-9a.89.89,0,0,1-.89-.89v-.89h-.78V42.3a.88.88,0,0,0,.88.88H40.35a.87.87,0,0,0,.88-.88V38.66h0V31h0V26.78a.89.89,0,0,0-.12-.42A2.55,2.55,0,0,0,42.47,24.12Zm-8.55,13.8V31.77h6.53v6.15Zm6-12H30a1.78,1.78,0,1,1,0-3.55H39.9a1.78,1.78,0,1,1,0,3.55Z"/><path class="cls-2" d="M31.58,35.82a2.58,2.58,0,0,0-4.21-2.23l-5.15,4.25a2.58,2.58,0,0,0,3.27,4l5.16-4.25A2.6,2.6,0,0,0,31.58,35.82ZM25,41.21a1.79,1.79,0,1,1-2.27-2.76l1.93-1.6L27.2,39.4Zm2.81-2.32-2.54-2.54,2.61-2.15A1.77,1.77,0,0,1,29,33.79h.17a1.77,1.77,0,0,1,1.61,2A1.73,1.73,0,0,1,30.15,37Z"/></g></g>
`,
];
