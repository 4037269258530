/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const scaleCompleted = [
  '60 60',
  `
  <path d="M27.4815 1.89129C28.6637 0.0646818 31.3363 0.0646796 32.5185 1.89129L36.9583 8.75101C37.635 9.79655 38.8873 10.3153 40.1051 10.0545L48.095 8.34328C50.2226 7.88762 52.1124 9.77744 51.6567 11.905L49.9455 19.8949C49.6847 21.1127 50.2035 22.365 51.249 23.0417L58.1087 27.4815C59.9353 28.6637 59.9353 31.3363 58.1087 32.5185L51.249 36.9583C50.2035 37.635 49.6847 38.8873 49.9455 40.1051L51.6567 48.095C52.1124 50.2226 50.2226 52.1124 48.095 51.6567L40.1051 49.9455C38.8873 49.6847 37.635 50.2035 36.9583 51.249L32.5185 58.1087C31.3363 59.9353 28.6637 59.9353 27.4815 58.1087L23.0417 51.249C22.365 50.2035 21.1127 49.6847 19.8949 49.9455L11.905 51.6567C9.77744 52.1124 7.88762 50.2226 8.34328 48.095L10.0545 40.1051C10.3153 38.8873 9.79655 37.635 8.75102 36.9583L1.89129 32.5185C0.0646818 31.3363 0.0646803 28.6637 1.89129 27.4815L8.75101 23.0417C9.79655 22.365 10.3153 21.1127 10.0545 19.8949L8.34328 11.905C7.88762 9.77744 9.77744 7.88762 11.905 8.34328L19.8949 10.0545C21.1127 10.3153 22.365 9.79655 23.0417 8.75101L27.4815 1.89129Z" fill="#47776E"/>
  <path d="M39.1739 18.2607H21.4783C19.7013 18.2607 18.2609 19.7012 18.2609 21.4781V39.1738C18.2609 40.9507 19.7013 42.3912 21.4783 42.3912H39.1739C40.9508 42.3912 42.3913 40.9507 42.3913 39.1738V21.4781C42.3913 19.7012 40.9508 18.2607 39.1739 18.2607Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M25.278 25.8591C25.3445 25.993 25.4472 26.1056 25.5743 26.1843C25.7015 26.2629 25.8481 26.3045 25.9976 26.3042H34.6545C34.804 26.3045 34.9506 26.2629 35.0778 26.1843C35.2049 26.1056 35.3076 25.993 35.3742 25.8591L36.4037 23.8022C36.4517 23.7054 36.4797 23.5999 36.486 23.4921C36.4924 23.3843 36.477 23.2763 36.4408 23.1745C36.4046 23.0728 36.3483 22.9793 36.2752 22.8998C36.2021 22.8202 36.1138 22.7562 36.0155 22.7115C34.2292 21.9008 32.2903 21.4814 30.3287 21.4814C28.3672 21.4814 26.4283 21.9008 24.642 22.7115C24.5434 22.7562 24.4548 22.8203 24.3816 22.9C24.3083 22.9797 24.2519 23.0734 24.2157 23.1754C24.1794 23.2774 24.1641 23.3857 24.1707 23.4937C24.1772 23.6018 24.2055 23.7074 24.2538 23.8043L25.278 25.8591Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M28.7174 31.5324C28.7174 30.4213 27.4573 29.5215 25.9022 29.5215C24.3471 29.5215 23.087 30.4213 23.087 31.5324V37.1628C23.087 37.6961 23.2988 38.2076 23.6759 38.5847C24.0531 38.9618 24.5645 39.1737 25.0978 39.1737C25.6312 39.1737 26.1426 38.9618 26.5197 38.5847C26.8969 38.2076 27.1087 37.6961 27.1087 37.1628V36.8024C27.1088 35.2034 27.591 33.6415 28.4922 32.3206V32.3206C28.6378 32.0833 28.7156 31.8107 28.7174 31.5324Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M31.9348 31.5324C31.9348 30.4213 33.195 29.5215 34.75 29.5215C36.3051 29.5215 37.5653 30.4213 37.5653 31.5324V37.1628C37.5653 37.6961 37.3534 38.2076 36.9763 38.5847C36.5992 38.9618 36.0877 39.1737 35.5544 39.1737C35.0211 39.1737 34.5096 38.9618 34.1325 38.5847C33.7554 38.2076 33.5435 37.6961 33.5435 37.1628V36.8024C33.5434 35.2034 33.0612 33.6415 32.16 32.3206V32.3206C32.0145 32.0833 31.9366 31.8107 31.9348 31.5324Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M27.913 21.6968V23.0867" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M32.7391 21.6968V23.0867" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
`,
];
