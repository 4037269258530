/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const favoriteFalse = [
  '20 18',
  `
<path d="M18.5212 3.54844C18.1948 2.89348 17.7224 2.32671 17.1423 1.89417C16.5623 1.46162 15.8909 1.17549 15.1828 1.05901C14.4747 0.942537 13.7498 0.998997 13.0669 1.22381C12.3841 1.44862 11.7626 1.83545 11.2529 2.35287L10 3.52546L8.74714 2.35287C8.23725 1.83554 7.61558 1.44885 6.93262 1.22423C6.24966 0.999598 5.52468 0.943359 4.81655 1.06008C4.10842 1.1768 3.43712 1.46318 2.85717 1.89598C2.27722 2.32877 1.80497 2.89576 1.47879 3.5509C1.053 4.41613 0.905938 5.39764 1.05877 6.35408C1.2116 7.31052 1.65644 8.19253 2.32923 8.8731L10 17L17.6708 8.87064C18.3436 8.19007 18.7884 7.30806 18.9412 6.35162C19.0941 5.39518 18.947 4.41367 18.5212 3.54844V3.54844Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
`,
];
