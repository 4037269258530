/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const calendar = [
  '24 24',
  `
  <path d="m18.10909,4.74207l-12.65573,0c-0.99851,0 -1.80796,0.82081 -1.80796,1.83334l0,12.83337c0,1.0125 0.80945,1.8333 1.80796,1.8333l12.65573,0c0.9985,0 1.8079,-0.8208 1.8079,-1.8333l0,-12.83337c0,-1.01253 -0.8094,-1.83334 -1.8079,-1.83334z" stroke="#656278" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" id="svg_1" fill="none"/>
  <path d="m15.3218,2.83301l0,3.66666" stroke="#656278" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" id="svg_2"/>
  <path d="m8.08984,2.83301l0,3.66666" stroke="#656278" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" id="svg_3"/>
  <path d="m3.57031,10.167l16.27159,0" stroke="#656278" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" id="svg_4"/>
`,
];
