/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const dumbell = [
  '38 38',
  `
  <path id="svg_1" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m13.04184,19.00509l11.9167,0"/>
  <path fill="none" id="svg_2" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m10.65834,29.00509c-0.63206,0 -1.23827,-0.2634 -1.68524,-0.7322c-0.44696,-0.4689 -0.69806,-1.1048 -0.69806,-1.7678l0,-14.99999c0,-0.66304 0.2511,-1.29893 0.69806,-1.76777c0.44697,-0.46884 1.05318,-0.73223 1.68524,-0.73223c0.6321,0 1.2383,0.26339 1.6853,0.73223c0.447,0.46884 0.6981,1.10473 0.6981,1.76777l0,14.99999c0,0.663 -0.2511,1.2989 -0.6981,1.7678c-0.447,0.4688 -1.0532,0.7322 -1.6853,0.7322z"/>
  <path fill="black" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m3.50847,19.00509l-2.38333,0"/>
  <path fill="none" id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m5.89177,14.0051c-0.6321,0 -1.23831,0.26339 -1.68527,0.73223c-0.44696,0.46884 -0.69806,1.10473 -0.69806,1.76776l0,5c0,0.663 0.2511,1.2989 0.69806,1.7678c0.44696,0.4688 1.05317,0.7322 1.68527,0.7322c0.6321,0 1.23831,-0.2634 1.68528,-0.7322c0.44696,-0.4689 0.69806,-1.1048 0.69806,-1.7678l0,-5c0,-0.66303 -0.2511,-1.29892 -0.69806,-1.76776c-0.44697,-0.46884 -1.05318,-0.73223 -1.68528,-0.73223z"/>
  <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m27.34174,29.00509c0.6321,0 1.2383,-0.2634 1.6853,-0.7322c0.4469,-0.4689 0.698,-1.1048 0.698,-1.7678l0,-14.99999c0,-0.66304 -0.2511,-1.29893 -0.698,-1.76777c-0.447,-0.46884 -1.0532,-0.73223 -1.6853,-0.73223c-0.6321,0 -1.2383,0.26339 -1.6853,0.73223c-0.4469,0.46884 -0.698,1.10473 -0.698,1.76777l0,14.99999c0,0.663 0.2511,1.2989 0.698,1.7678c0.447,0.4688 1.0532,0.7322 1.6853,0.7322z"/>
  <path id="svg_6" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m34.49204,19.00509l2.3834,0"/>
  <path fill="none" id="svg_7" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m32.10854,14.0051c0.6321,0 1.2383,0.26339 1.6853,0.73223c0.447,0.46884 0.6981,1.10473 0.6981,1.76776l0,5c0,0.663 -0.2511,1.2989 -0.6981,1.7678c-0.447,0.4688 -1.0532,0.7322 -1.6853,0.7322c-0.6321,0 -1.2383,-0.2634 -1.6852,-0.7322c-0.447,-0.4689 -0.6981,-1.1048 -0.6981,-1.7678l0,-5c0,-0.66303 0.2511,-1.29892 0.6981,-1.76776c0.4469,-0.46884 1.0531,-0.73223 1.6852,-0.73223z"/>
`,
];
