/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const dumbellCompleted = [
  '66.86 67.16',
  `
<defs><style>.cls-1{fill:#47776e;}.cls-2{fill:#fff;}</style></defs>
<g id="Layer_2" data-name="Layer 2">
<g id="Layer_6" data-name="Layer 6">
<path class="cls-1" d="M9.55,15.33l1.53,5.92a5.18,5.18,0,0,1,.11,3.36,5.27,5.27,0,0,1-2.25,2.13l-6,3.75c-1.46.9-3.11,2.19-2.87,3.89.19,1.3,1.45,2.14,2.59,2.81l6.08,3.57a5.29,5.29,0,0,1,2.49,2.31,5.36,5.36,0,0,1-.09,3.12L9.43,53.27c-.35,1.52-.61,3.37.54,4.42s2.78.68,4.2.31l6.6-1.74a5.36,5.36,0,0,1,3.42-.12,5.31,5.31,0,0,1,2.17,2.3l3.88,6.26c4.35,7,8.05-3,10.2-6.38A4.55,4.55,0,0,1,43.19,56a5.27,5.27,0,0,1,2.12.17l7.88,1.77c1.34.3,2.92.55,3.92-.39s.83-2.83.46-4.3l-1.77-7a4.86,4.86,0,0,1-.13-2.94,4.9,4.9,0,0,1,2.23-2.13L64,37.5c1.31-.81,2.78-1.85,2.86-3.38.09-1.76-1.65-3-3.18-3.86l-5.32-3c-1.15-.66-2.4-1.44-2.72-2.72a4.87,4.87,0,0,1,.14-2.27l1.8-8.14c.27-1.19.5-2.57-.24-3.54-1-1.32-3-1-4.62-.63L46,11.64a5.73,5.73,0,0,1-2.62.25c-1.83-.39-2.78-2.34-3.61-4a36.72,36.72,0,0,0-4.15-6.52A3.45,3.45,0,0,0,34.16.1c-1.56-.53-2.95,1.12-3.79,2.54L26.59,9a5.31,5.31,0,0,1-2.33,2.48,5.28,5.28,0,0,1-3.07-.1L14.28,9.78c-1.66-.39-3.74-.65-4.79.71-.82,1.07-.52,2.6-.18,3.91Z"/>
<path class="cls-2" d="M26.9,42.07a2.37,2.37,0,0,1-2.37-2.37V27.46a2.37,2.37,0,1,1,4.74,0V39.7A2.37,2.37,0,0,1,26.9,42.07Zm0-16.2a1.59,1.59,0,0,0-1.58,1.59V39.7a1.59,1.59,0,0,0,3.17,0V27.46A1.59,1.59,0,0,0,26.9,25.87Z"/>
<path class="cls-2" d="M23,38a2.37,2.37,0,0,1-2.37-2.37V31.5a2.37,2.37,0,1,1,4.74,0v4.16A2.37,2.37,0,0,1,23,38Zm0-8.12a1.59,1.59,0,0,0-1.58,1.59v4.16a1.58,1.58,0,1,0,3.16,0V31.5A1.59,1.59,0,0,0,23,29.91Z"/>
<rect class="cls-2" x="18.46" y="32.88" width="2.51" height="1.53"/>
<path class="cls-2" d="M40,42.07a2.38,2.38,0,0,1-2.37-2.37V27.46a2.37,2.37,0,1,1,4.74,0V39.7A2.37,2.37,0,0,1,40,42.07Zm0-16.2a1.59,1.59,0,0,0-1.58,1.59V39.7a1.59,1.59,0,0,0,3.17,0V27.46A1.59,1.59,0,0,0,40,25.87Z"/>
<path class="cls-2" d="M43.91,38a2.37,2.37,0,0,1-2.36-2.37V31.5a2.37,2.37,0,1,1,4.73,0v4.16A2.37,2.37,0,0,1,43.91,38Zm0-8.12a1.59,1.59,0,0,0-1.58,1.59v4.16a1.59,1.59,0,0,0,3.17,0V31.5A1.59,1.59,0,0,0,43.91,29.91Z"/>
<rect class="cls-2" x="45.89" y="32.88" width="2.51" height="1.53" transform="translate(94.29 67.28) rotate(-180)"/>
<rect class="cls-2" x="28.88" y="32.88" width="9.11" height="1.53"/>
</g>
</g>`,
];
