export default {
  // Quand l'élément lié est inséré dans le DOM...
  init() {
    document.addEventListener('click', (event) => {
      const { target } = event;
      if (!target || !target.hasAttribute('data-track-category') || !target.hasAttribute('data-track-action')) {
        return;
      }
      this.track(
        target.getAttribute('data-track-category'),
        target.getAttribute('data-track-action'),
        target.getAttribute('data-track-name'),
        target.getAttribute('data-track-value')
      );
    });
  },

  track(category = 'Default event', action = 'Default Action', name = null, value = null) {
    // eslint-disable-next-line
    if (window._paq) {
      // eslint-disable-next-line
      window._paq.push(['trackEvent', category, action, name, value]);
    } else {
      console.log('_paq not initialized, tracking impossible');
    }
  },

};
