/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const upload = [
  '24 24',
  `
  <circle cx="12" cy="12" r="12" fill="#47776E"/>
  <path d="M9.57684 16.9616V11.8188H7.56191C7.48526 11.8195 7.40999 11.7988 7.34492 11.7591C7.27984 11.7194 7.22767 11.6624 7.1945 11.5947C7.16133 11.527 7.14854 11.4514 7.15763 11.3768C7.16672 11.3023 7.19732 11.2318 7.24583 11.1737L11.6838 5.63521C11.7223 5.58956 11.7706 5.55281 11.8252 5.52757C11.8798 5.50234 11.9395 5.48926 11.9999 5.48926C12.0603 5.48926 12.12 5.50234 12.1746 5.52757C12.2293 5.55281 12.2775 5.58956 12.316 5.63521L16.754 11.1737C16.8025 11.2318 16.8331 11.3023 16.8422 11.3768C16.8513 11.4514 16.8385 11.527 16.8053 11.5947C16.7722 11.6624 16.72 11.7194 16.6549 11.7591C16.5898 11.7988 16.5146 11.8195 16.4379 11.8188H14.423V16.9616C14.4228 17.0138 14.4121 17.0654 14.3915 17.1135C14.3709 17.1616 14.3409 17.2053 14.3031 17.242C14.2653 17.2787 14.2205 17.3078 14.1712 17.3276C14.1219 17.3474 14.0692 17.3574 14.0159 17.3572H9.98391C9.93067 17.3574 9.8779 17.3474 9.82863 17.3276C9.77935 17.3078 9.73453 17.2787 9.69673 17.242C9.65893 17.2053 9.62889 17.1616 9.60832 17.1135C9.58774 17.0654 9.57705 17.0138 9.57684 16.9616Z" fill="white"/>
`,
];
