/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const restaurant = [
  '34 34',
  `
  <defs>
    <clipPath id="clip0">
      <rect id="svg_1" transform="translate(0.5 0.5)" fill="white" height="33" width="33"/>
    </clipPath>
   </defs>
   <g class="layer">
    <title>Layer 1</title>
    <g id="svg_2" clip-path="url(#clip0)">
      <path fill="none" id="svg_3" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m9.77979,1.53125l0,30.93755"/>
      <path fill="none" id="svg_4" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m13.9048,1.53125l0,9.28125c-0.0033,1.093 -0.4389,2.1403 -1.2118,2.9132c-0.7729,0.7729 -1.8202,1.2085 -2.91322,1.2118c-1.09301,-0.0033 -2.14032,-0.4389 -2.9132,-1.2118c-0.77289,-0.7729 -1.20853,-1.8202 -1.21179,-2.9132l0,-9.28125"/>
      <path fill="none" id="svg_5" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="m22.1547,22.1562l4.125,0c0.2736,0.0104 0.5465,-0.0358 0.8014,-0.1358c0.255,-0.1 0.4866,-0.2515 0.6802,-0.4451c0.1936,-0.1937 0.3452,-0.4252 0.4451,-0.6802c0.1,-0.2549 0.1462,-0.5278 0.1358,-0.8014c-0.2214,-5.9235 -0.4606,-12.12754 -4.2804,-18.09091c-0.1224,-0.18657 -0.3017,-0.32878 -0.5112,-0.40559c-0.2096,-0.07681 -0.4383,-0.08415 -0.6523,-0.02093c-0.214,0.06323 -0.402,0.19365 -0.5362,0.37199c-0.1341,0.17833 -0.2074,0.39511 -0.2088,0.61828l0,29.90216"/>
    </g>
   </g>
`,
];
