/* eslint-disable max-len */
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
// eslint-disable-next-line import/prefer-default-export
export const restaurantCompleted = [
  '66.86 67.16',
  `
<defs><style>.cls-1{fill:#47776e;}.cls-2{fill:#fff;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M9.55,15.33l1.53,5.92a5.18,5.18,0,0,1,.11,3.36,5.27,5.27,0,0,1-2.25,2.13l-6,3.75c-1.46.9-3.11,2.19-2.87,3.89.19,1.3,1.45,2.14,2.59,2.81l6.08,3.57a5.29,5.29,0,0,1,2.49,2.31,5.36,5.36,0,0,1-.09,3.12L9.43,53.27c-.35,1.52-.61,3.37.54,4.42s2.78.68,4.2.31l6.6-1.74a5.36,5.36,0,0,1,3.42-.12,5.31,5.31,0,0,1,2.17,2.3l3.88,6.26c4.35,7,8.05-3,10.2-6.38A4.55,4.55,0,0,1,43.19,56a5.27,5.27,0,0,1,2.12.17l7.88,1.77c1.34.3,2.92.55,3.92-.39s.83-2.83.46-4.3l-1.77-7a4.86,4.86,0,0,1-.13-2.94,4.9,4.9,0,0,1,2.23-2.13L64,37.5c1.31-.81,2.78-1.85,2.86-3.38.09-1.76-1.65-3-3.18-3.86l-5.32-3c-1.15-.66-2.4-1.44-2.72-2.72a4.87,4.87,0,0,1,.14-2.27l1.8-8.14c.27-1.19.5-2.57-.24-3.54-1-1.32-3-1-4.62-.63L46,11.64a5.73,5.73,0,0,1-2.62.25c-1.83-.39-2.78-2.34-3.61-4a36.72,36.72,0,0,0-4.15-6.52A3.45,3.45,0,0,0,34.16.1c-1.56-.53-2.95,1.12-3.79,2.54L26.59,9a5.31,5.31,0,0,1-2.33,2.48,5.28,5.28,0,0,1-3.07-.1L14.28,9.78c-1.66-.39-3.74-.65-4.79.71-.82,1.07-.52,2.6-.18,3.91Z"/><path class="cls-2" d="M42.83,37.19c-.17-9.27-1.42-14.6-3.73-15.84a2,2,0,0,0-1.92,0l-.22.14V45.74h.94V37.52l4.94.17Zm-4.93-.62V22.05a1.1,1.1,0,0,1,.78.14c1.09.62,2.94,3.33,3.2,14.52Z"/><path class="cls-2" d="M32.27,21.24v7.82a2.17,2.17,0,0,1-.88,1.72,2.36,2.36,0,0,1-1.73.49v-10h-.79v10a2.35,2.35,0,0,1-1.73-.49,2.19,2.19,0,0,1-.89-1.72V21.24h-.78v7.82a3,3,0,0,0,1.19,2.35,3.23,3.23,0,0,0,2,.67h.25V45.59h.79V32.07h.25a3.25,3.25,0,0,0,2-.67,3,3,0,0,0,1.19-2.35V21.24Z"/></g></g>
`,
];
