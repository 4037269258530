import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
} from '@coreui/icons';

import { logo } from './logo';
import { arrowUp } from './arrowUp';
import { chapterDone } from './chapterDone';
import { chapterInProgress } from './chapterInProgress';
import { chapterToDo } from './chapterToDo';
import { calendar } from './calendar';
import { info } from './info';
import { scale } from './objectives/scale';
import { scaleCompleted } from './objectives/scaleCompleted';
import { dumbell } from './objectives/dumbell';
import { dumbellCompleted } from './objectives/dumbellCompleted';
import { bed } from './objectives/bed';
import { bedCompleted } from './objectives/bedCompleted';
import { care } from './objectives/care';
import { careCompleted } from './objectives/careCompleted';
import { medication } from './objectives/medication';
import { medicationCompleted } from './objectives/medicationCompleted';
import { mountain } from './objectives/mountain';
import { mountainCompleted } from './objectives/mountainCompleted';
import { restaurant } from './objectives/restaurant';
import { restaurantCompleted } from './objectives/restaurantCompleted';
import { menuDots } from './menuDots';
import { cancel } from './cancel';
import { edit } from './edit';
import { smile } from './smile';
import { arrowLeft } from './arrowLeft';
import { upload } from './upload';
import { editDocument } from './editDocument';
import { favoriteTrue } from './favoriteTrue';
import { favoriteFalse } from './favoriteFalse';

// eslint-disable-next-line import/prefer-default-export
export const iconsSet = Object.assign(
  {},
  {
    logo,
    arrowUp,
    chapterDone,
    chapterInProgress,
    chapterToDo,
    calendar,
    info,
    scale,
    scaleCompleted,
    dumbell,
    dumbellCompleted,
    bed,
    bedCompleted,
    care,
    careCompleted,
    medication,
    medicationCompleted,
    mountain,
    mountainCompleted,
    restaurant,
    restaurantCompleted,
    menuDots,
    cancel,
    edit,
    smile,
    arrowLeft,
    upload,
    editDocument,
    favoriteTrue,
    favoriteFalse
  },
  {
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
  },
);
